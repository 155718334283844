import React, { PropsWithChildren, ReactNode, ReactElement } from "react";
import styled from "styled-components";

const FormFieldsStyled = styled.div`
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 70%;
  }
`;

const FormFields = ({
  children,
}: PropsWithChildren<{ children?: ReactNode }>): ReactElement => (
  <FormFieldsStyled>{children}</FormFieldsStyled>
);

export default FormFields;
