// DEPENDENCIES
import React, { ReactElement } from "react";

// CONFIG
import { config, getFormComponents } from "../../configs/single-donation";

// SCHEMA
import { initialFormValues } from "../../schema/single-donation";

// CONTEXTS
import FormNameContext from "../../contexts/FormNameContext";

// HELPERS
import { getTransactionID } from "../../helpers/functions/shared";

// COMPONENTS
import DonationForm from "../../components/single-donation/forms/DonationForm";
import DonationFormWrapper from "../../components/single-donation/DonationFormWrapper";

// TYPES
import { PagePropTypes } from "../../helpers/types/shared";

const DonationPage = ({ params, page }: PagePropTypes): ReactElement => {
  const formName = params.form;

  if (typeof window !== "undefined") {
    if (!JSON.parse(sessionStorage.getItem(`formData-${formName}`))) {
      let initialDonationFormValues = initialFormValues;
      const formComponents = getFormComponents(formName);
      if (formComponents.restriction) {
        initialDonationFormValues = {
          ...initialFormValues,
          destinationRadioGroup: "choose",
          restriction: formComponents.restriction,
        };
      }

      sessionStorage.setItem(
        `formData-${formName}`,
        JSON.stringify(initialDonationFormValues)
      );
    }
  }
  const hasTransactionID = getTransactionID(formName) !== null;
  if (hasTransactionID) {
    sessionStorage.removeItem(`transactionId-${formName}`);
  }

  return (
    <FormNameContext.Provider value={formName}>
      <DonationFormWrapper page={page}>
        <DonationForm />
      </DonationFormWrapper>
    </FormNameContext.Provider>
  );
};

export async function getStaticPaths() {
  const paths = [];
  for (const [key] of Object.entries(config)) {
    paths.push({ params: { form: key } });
  }

  return { paths, fallback: false };
}

export async function getStaticProps({ params }: any) {
  return {
    props: {
      params,
      config: config[params.form],
      page: "Your donation",
    },
  };
}

export default DonationPage;
