import React, { PropsWithChildren, ReactElement, ReactNode } from "react";
import styled from "styled-components";

const Error = styled.p`
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.textError};
  margin: ${({ theme }) => theme.spacing.xxs} 0 0;
  font-weight: bold;

  &:empty {
    margin: 0;
  }
`;

const FieldErrorMessage = ({
  children,
}: PropsWithChildren<{ children?: ReactNode }>): ReactElement => (
  <Error>{children}</Error>
);

export default FieldErrorMessage;
