const motivations = [
  {
    value: "In memory of someone",
    label: "In memory of someone",
  },
  {
    value: "I or loved one beaten cancer",
    label: "I or a loved one has beaten cancer",
  },
  {
    value: "I or a loved one has cancer",
    label: "I or a loved one has cancer",
  },
  {
    value: "Different reason",
    label: "Different reason",
  },
];

export default motivations;
