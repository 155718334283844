import { useContext, useState, useEffect } from "react";
import type { ReactNode } from "react";
import { datadogRum } from "@datadog/browser-rum";
import type { OptimizelyDecision } from "@optimizely/optimizely-sdk";
import { OptimizelyContext } from "../contexts/OptimizelyContext";
import type { Option } from "../helpers/types/shared";

type OptimizelyExperimentProps = {
  experiment: string;
  children: (variation: string) => ReactNode;
  defaultComponent: ReactNode;
  render: ReactNode;
  childrenPositionOnVariant: {
    [variant: string]: "start" | "end";
  };
};

const OptimizelyExperiment = ({
  experiment,
  children,
  defaultComponent,
  render,
  childrenPositionOnVariant,
}: OptimizelyExperimentProps) => {
  const [optimizelyContext] = useContext(OptimizelyContext);
  const { optimizelyUserContext: user, optimizelyAudience } = optimizelyContext;
  const [decision, setDecision] = useState<Option<OptimizelyDecision>>(null);
  const enabled = decision?.enabled;
  const [variation, setVariation] = useState("");
  const childrenPosition = childrenPositionOnVariant[variation];

  useEffect(() => {
    if (user) {
      const expDecision = user.decide(experiment);
      setDecision(expDecision);

      // TODO This is a hack that needs to be reworked properly in useOptimizelyTrack
      if (experiment === "your_donation_condensed_amount_view_2") {
        user.trackEvent("start_regular_donation");
      }
    }
  }, [!!user, optimizelyAudience !== user?.getAttributes().audience]);

  useEffect(() => {
    if (decision && enabled) {
      setVariation(decision.variationKey);

      type Experiment = {
        experiment: string;
        variation: string;
      };

      const newExperiment: Experiment = {
        experiment,
        variation: decision.variationKey,
      };
      const experiments =
        (datadogRum.getUser().experiments as Array<Experiment>) ?? [];
      const maybeDuplicateIndex = experiments.findIndex(
        (exp) => exp.experiment === newExperiment.experiment
      );

      if (maybeDuplicateIndex >= 0) {
        experiments[maybeDuplicateIndex].variation = newExperiment.variation;
      } else {
        experiments.push(newExperiment);
      }

      datadogRum.setUserProperty("experiments", experiments);
    } else {
      setVariation("default");
    }
  }, [decision]);

  return (
    <>
      {childrenPosition === "start" &&
        (enabled ? children(variation) : defaultComponent)}
      {render}
      {childrenPosition === "end" &&
        (enabled ? children(variation) : defaultComponent)}
    </>
  );
};

export default OptimizelyExperiment;
