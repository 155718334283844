const researchAreas = [
  {
    label: "Bowel cancer",
    value: "RES005",
  },
  {
    label: "Brain tumours",
    value: "RES038",
  },
  {
    label: "Breast cancer",
    value: "RES003",
  },
  {
    label: "Children's cancers",
    value: "RES814",
  },
  {
    label: "Developing better treatments",
    value: "RES828",
  },
  {
    label: "Diagnosing cancer early",
    value: "RES769",
  },
  {
    label: "Leukaemia",
    value: "RES009",
  },
  {
    label: "Lung cancer",
    value: "RES004",
  },
  {
    label: "Lymphoma",
    value: "RES010",
  },
  {
    label: "Oesophageal cancer",
    value: "RES085",
  },
  {
    label: "Ovarian cancer",
    value: "RES008",
  },
  {
    label: "Pancreatic cancer",
    value: "RES011",
  },
  {
    label: "Preventing cancer",
    value: "RES813",
  },
  {
    label: "Prostate cancer",
    value: "RES006",
  },
  {
    label: "Rare cancers",
    value: "RES829",
  },
  {
    label: "Skin cancer",
    value: "RES490",
  },
  {
    label: "The cancer type or area of research isn't listed",
    value: "Unlisted",
  },
] as const;

export type RestrictionCode = Exclude<
  typeof researchAreas[number]["value"],
  "Unlisted"
>;

export default [...researchAreas];
