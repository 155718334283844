import React, { FormHTMLAttributes, ReactElement } from "react";
import styled from "styled-components";
import { ThemeType } from "@cruk/cruk-react-components";

type FormGroupTypes = FormHTMLAttributes<Record<string, unknown>> & {
  topborder?: boolean;
  noborder?: boolean;
  children: ReactElement;
};

type FormGroupBorderTypes = FormGroupTypes & {
  theme: ThemeType;
};

const FormGroupStyled = styled.div<FormGroupTypes>`
  max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.m} 0;
  border-bottom: 0;
  border-bottom: ${({ noborder, theme }: FormGroupBorderTypes) =>
    `${noborder ? "0" : "1px"} solid ${theme.tokenColors.grey_900} `};
  border-top: ${({ topborder, theme }: FormGroupBorderTypes) =>
    `${topborder ? "1px" : "0"} solid ${theme.tokenColors.grey_900} `};
`;

const FormGroup = ({
  noborder,
  topborder,
  children,
}: FormGroupTypes): ReactElement => (
  <FormGroupStyled noborder={noborder} topborder={topborder}>
    {children}
  </FormGroupStyled>
);

export default FormGroup;
