import type { Client, OptimizelyUserContext } from "@optimizely/optimizely-sdk";
import { useEffect, useState } from "react";

type Props = {
  client: Client;
  user: OptimizelyUserContext;
  event: string;
  donationAmount?: string;
  calculateRevenue?: (amount: number) => number;
};

const useOptimizelyTrack = ({
  client,
  user,
  event,
  donationAmount,
  calculateRevenue,
}: Props) => {
  const [hasTracked, setHasTracked] = useState(false);

  const optimizelyTracking = () => {
    if (
      typeof calculateRevenue !== "undefined" &&
      typeof donationAmount !== "undefined"
    ) {
      user?.trackEvent(event, {
        revenue: calculateRevenue(Number(donationAmount)),
      });
    }
    user?.trackEvent(event);
  };

  useEffect(() => {
    if (client && !hasTracked) {
      optimizelyTracking();
      setHasTracked(true);
    }
  }, [client]);
};

export default useOptimizelyTrack;
