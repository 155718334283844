import React, {
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
  ReactElement,
} from "react";
import styled, { css } from "styled-components";
import { Field, FieldProps } from "formik";
import {
  Box,
  Button,
  Link,
  Heading,
  ThemeType,
  TextField,
} from "@cruk/cruk-react-components";
// CONTEXT
import FormNameContext from "../../contexts/FormNameContext";
import DonationContext from "../../contexts/DonationContext";
import ThemeNameContext from "../../contexts/ThemeNameContext";
import VersionsContext from "../../contexts/VersionsContext";
import { OptimizelyContext } from "../../contexts/OptimizelyContext";
// CONFIG
import { getFormAdmin, getFormComponents } from "../../configs/single-donation";
// HELPERS
import { FormPropsType } from "../../helpers/types/shared";
import useDataLayer from "../../helpers/useDataLayer";
import { getValidFormVersions } from "../../helpers/functions/single-donation";
// COMPONENTS
import FormGroup from "../FormGroup";
import FormFields from "../FormFields";
import FieldErrorMessage from "../FieldErrorMessage";
import OptimizelyExperiment from "../OptimizelyExperiment";
// DATA
import donationFrequencies from "../../data/donationFrequencies";
// STYLES
import { RequiredParagraph } from "../styles";
import { formatNumberToCurrencyString } from "../../helpers/functions/shared";

type AmountLabelProps = HTMLAttributes<HTMLDivElement> & {
  formTheme: string;
  theme?: ThemeType;
};

const Fields = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex;
    padding-top: 0.5rem;
  }
`;

const RadioButtonGroup = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex;
    flex-grow: 1;
  }

  > div {
    position: relative;
    display: block;
    padding: 0 0 ${({ theme }) => theme.spacing.s};
    margin: 0;

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      flex-grow: 1;
      display: inline-block;
      margin: 0 ${({ theme }) => theme.spacing.s} 0 0;
      padding: 0;
    }
  }

  label {
    display: block;
    cursor: pointer;
    height: 48px;
    font-family: ${({ theme }) => theme.typography.fontFamilyBase};
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: normal;
    text-align: center;
    padding: 0.5rem 2rem;
    margin: 0;
    border-style: solid;
    border-width: ${({ theme }) => theme.button.buttonBorderThickness};
    border-color: ${({ theme }) => theme.colors.buttonPrimaryBorder};

    ${({ theme, formTheme }: AmountLabelProps) =>
      (formTheme === "SU2C" &&
        css`
          border-radius: ${theme.button.borderRadius};
          border-color: ${theme.colors.primary};
          font-weight: bold;
        `) ||
      css`
        border-color: ${theme.tokenColors.grey_200};
        background-color: ${theme.tokenColors.grey_200};
      `}
  }

  input {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    zoom: 1;
    opacity: 0;
    z-index: 1;

    &:focus,
    &:hover,
    &:checked {
      + label {
        background-color: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.textLight};
      }
    }
  }
`;

const OtherAmountDiv = styled.div<{ extraTopSpacing: boolean }>`
  position: relative;
  margin-top: ${({ theme, extraTopSpacing }) =>
    extraTopSpacing ? theme.spacing.m : theme.spacing.s};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: ${({ theme, extraTopSpacing }) =>
      extraTopSpacing ? theme.spacing.xs : theme.spacing.none};
  }

  label {
    > span:first-of-type {
      position: absolute;
      top: -${({ theme }) => theme.spacing.s};
      margin-bottom: ${({ theme }) => theme.spacing.none};
    }
  }

  .textField__icon {
    position: absolute;
    bottom: 12px;
    left: 15px;
    z-index: 3;
    font-weight: ${({ theme }) => theme.typography.fontWeightHeavy};
  }
`;

const TextFieldWrapper = styled.span`
  position: relative;
  display: block;
`;

// Optimizely experiment styles
const CondensedAmountSection = styled.div`
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 70%;
  }
`;

const CondensedViewIcons = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    line-height: 1.75em;
  }
`;

const CondensedViewContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  padding: ${({ theme }) => theme.spacing.xs};
`;

const CondensedViewButton = styled(Button)`
  font-family: ${({ theme }) => theme.typography.fontFamilyBase};
  font-weight: bold;
  text-decoration: underline;
  min-height: 0;
`;

const CondensedViewLink = styled(Link)`
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  svg {
    display: none;
  }
`;

const CalendarIcon = styled.img`
  width: 24px;
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-top: -4px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: -7px;
    width: 30px;
  }
`;

const DDLogo = styled.img`
  width: 74px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 86px;
  }
`;

const updateCheckedAmount = (
  nudges: Array<number>,
  radioAmount: string | number,
  handler: () => void
) => {
  const amount =
    typeof radioAmount === "string" ? parseInt(radioAmount, 10) : radioAmount;

  if (!Number.isNaN(amount) && !nudges?.includes(amount)) {
    handler();
  }
};
const validateAmount = (amount: string, frequency: string) => {
  let max = 0;
  if (frequency === "monthly") {
    max = 850;
  } else if (frequency === "quarterly") {
    max = 2500;
  } else if (frequency === "annually") {
    max = 10000;
  } else {
    max = 100000;
  }
  const isAmountValid = !!(Number(amount) >= 2 && Number(amount) <= max);
  return isAmountValid;
};

type AmountSelectionProps = FormPropsType & {
  canExperiment?: boolean;
};

const AmountSelection = ({
  values,
  errors,
  handleBlur,
  handleChange,
  touched,
  setFieldValue,
  canExperiment = false,
}: AmountSelectionProps): ReactElement => {
  const { donationData, setDonationData } = useContext(DonationContext);
  const { formVersion } = useContext(VersionsContext);
  const formName = useContext(FormNameContext);
  const formTheme = useContext(ThemeNameContext);
  const [checkedAmount, setCheckedAmount] = useState(
    values.donationAmount.amountRadioGroup
      ? values.donationAmount.amountRadioGroup
      : ""
  );
  const [checkedfrequency, setCheckedfrequency] = useState(
    values.frequency ? values.frequency : ""
  );
  const { pushError } = useDataLayer();
  const formAdmin = getFormAdmin(formName);
  const formComponents = getFormComponents(formName);
  const nudges =
    formVersion === "regular"
      ? formComponents.donationAmount.regularNudges
      : formComponents.donationAmount.nudges;
  const [showButton, setShowButton] = useState(false);
  const [optimizelyContext, setOptimizelyContext] =
    useContext(OptimizelyContext);
  const { optimizelyUserContext: user, isTestAudience } = optimizelyContext;

  const buttonText = formVersion === "regular" ? "one-off" : "regular";
  const searchParams = new URLSearchParams(window.location.search);
  const urlAmount = searchParams.get("amount");
  const frequencyQueryString = searchParams.get("frequency");
  const buttonQueryString = searchParams;
  buttonQueryString.set(
    "type",
    formVersion === "regular" ? "single" : "regular"
  );
  if (formVersion === "regular" && buttonQueryString.has("frequency")) {
    buttonQueryString.delete("frequency");
  }
  if (buttonQueryString.has("amount")) buttonQueryString.delete("amount");
  const buttonUrl = buttonQueryString.toString();

  // Optimizely experiment variables
  const validFrequencies = ["monthly", "quarterly", "annually"]; // TODO Check codebase for duplicate
  const frequencyString = frequencyQueryString || "one-off";
  const lowercaseFrequency = frequencyString.toLowerCase();

  const areUrlValuesValid =
    validFrequencies.includes(lowercaseFrequency) || formVersion === "single"
      ? validateAmount(urlAmount, lowercaseFrequency)
      : false;
  const canShowCondensedAmountView = canExperiment && areUrlValuesValid;
  const [showCondensed, setShowCondensed] = useState(
    canShowCondensedAmountView
  );

  const formatAmount = formatNumberToCurrencyString(Number(urlAmount), true);
  // END
  useEffect(() => {
    if (canShowCondensedAmountView && !isTestAudience) {
      setOptimizelyContext({
        ...optimizelyContext,
        optimizelyAudience: "condensed-amount-view-bucket",
      });
    }
  }, []);
  useEffect(() => {
    const urlAmountNum = Number(urlAmount);
    const donationDataVals = values;
    if (urlAmountNum > 0) {
      if (!nudges || !nudges.includes(urlAmountNum)) {
        donationDataVals.donationAmount.otherAmount = urlAmountNum.toFixed(2);
        donationDataVals.donationAmount.amountRadioGroup = "";
      } else {
        donationDataVals.donationAmount.amountRadioGroup =
          urlAmountNum.toString(10);
        donationDataVals.donationAmount.otherAmount = "";
      }
    }
    setDonationData(donationDataVals);
    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        `formData-${formName}`,
        JSON.stringify(donationDataVals)
      );
    }
  }, []);

  useEffect(() => {
    const donationDataVals = values;
    const validFormVersions = getValidFormVersions(formAdmin);
    const isValidFrequency = donationFrequencies.filter((frequency) =>
      frequency.toLowerCase().includes(frequencyQueryString?.toLowerCase())
    );
    const hasButton =
      !!(formVersion === "regular" && validFormVersions.includes("single")) ||
      !!(formVersion === "single" && validFormVersions.includes("regular"));
    setShowButton(hasButton);

    if (isValidFrequency.length > 0) {
      const lowerCasefrequencyQueryString = frequencyQueryString.toLowerCase();
      const formattedFrequency =
        lowerCasefrequencyQueryString.charAt(0).toUpperCase() +
        lowerCasefrequencyQueryString.slice(1);
      setFieldValue("frequency", formattedFrequency);
      donationDataVals.frequency = formattedFrequency;
    }
    if (formVersion === "single") {
      setFieldValue("frequency", "");
      donationDataVals.frequency = "";
    }
    updateCheckedAmount(
      nudges,
      donationDataVals.donationAmount.amountRadioGroup,
      () => {
        setFieldValue("donationAmount.amountRadioGroup", "");
        donationDataVals.donationAmount.amountRadioGroup = "";
      }
    );
    setDonationData(donationDataVals);
    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        `formData-${formName}`,
        JSON.stringify(donationDataVals)
      );
    }
  }, [formVersion]);

  useEffect(() => {
    setCheckedAmount(values.donationAmount.amountRadioGroup);
  }, [values.donationAmount.amountRadioGroup]);

  useEffect(() => {
    setCheckedfrequency(values.frequency);
  }, [values.frequency]);

  useEffect(() => {
    const errorDelay = setTimeout(() => {
      if (
        !!touched.donationAmount &&
        errors.donationAmount &&
        errors.donationAmount.otherAmount
      ) {
        const otherAmountError = errors.donationAmount.otherAmount as string;
        pushError("", "validation", `donationAmount: ${otherAmountError}`);
      }
    }, 1000);
    return (): void => clearTimeout(errorDelay);
  }, [errors.donationAmount, touched.donationAmount]);

  if (canShowCondensedAmountView) {
    return (
      <OptimizelyExperiment
        experiment="your_donation_condensed_amount_view_2"
        render={null}
        childrenPositionOnVariant={{
          condensed_view: "start",
          original_view: "start",
          default: "start",
        }}
        defaultComponent={
          <FormGroup>
            <FormFields>
              <fieldset
                tabIndex={-1}
                aria-invalid={
                  errors.donationAmount && touched.donationAmount
                    ? "true"
                    : null
                }
                aria-describedby={
                  errors.donationAmount && touched.donationAmount
                    ? "donationAmount-error"
                    : null
                }
              >
                <legend>
                  <Heading h2>Donation amount</Heading>
                  <RequiredParagraph id="amountRadioGroupLabel">
                    Please {nudges ? "choose" : "enter"} an amount for your{" "}
                    {formVersion === "regular" ? "regular" : ""} donation.{" "}
                    <span>(required)</span>
                  </RequiredParagraph>
                </legend>
                <Fields>
                  {nudges && nudges.length > 0 ? (
                    <RadioButtonGroup
                      formTheme={formTheme}
                      id="amountRadioGroup"
                      role="radiogroup"
                      aria-required="true"
                      aria-labelledby="amountRadioGroupLabel"
                    >
                      {nudges.map((nudge: number) => {
                        const amount = nudge.toString(10);

                        return (
                          <div key={`amount${amount}`}>
                            <Field name="donationAmount.amountRadioGroup">
                              {({ field, form }: FieldProps): ReactElement => (
                                <input
                                  {...field}
                                  id={`amount${amount}`}
                                  type="radio"
                                  value={amount}
                                  data-cy={`amount-sel-${amount}`}
                                  checked={amount === checkedAmount}
                                  onBlur={(e): void => {
                                    handleBlur(e);
                                  }}
                                  onChange={(e): void => {
                                    form.setFieldValue(
                                      "donationAmount.otherAmount",
                                      ""
                                    );
                                    handleChange(e);
                                    setDonationData(donationData);
                                  }}
                                />
                              )}
                            </Field>
                            <label htmlFor={`amount${amount}`}>
                              £<span>{amount}</span>
                            </label>
                          </div>
                        );
                      })}
                    </RadioButtonGroup>
                  ) : (
                    ""
                  )}
                  <OtherAmountDiv extraTopSpacing={!nudges}>
                    <Field name="donationAmount.otherAmount">
                      {({ field, form }: FieldProps): ReactElement => (
                        <TextFieldWrapper>
                          <TextField
                            id="otherAmount"
                            data-testid="otherAmount"
                            value={values.donationAmount.otherAmount}
                            name={field.name}
                            label={nudges ? "Other amount" : "Amount"}
                            extraLeft="£"
                            aria-invalid={
                              errors.donationAmount && touched.donationAmount
                                ? errors.donationAmount.otherAmount &&
                                  touched.donationAmount.otherAmount
                                  ? "true"
                                  : null
                                : null
                            }
                            aria-describedby={
                              errors.donationAmount && touched.donationAmount
                                ? errors.donationAmount.otherAmount &&
                                  touched.donationAmount.otherAmount
                                  ? "otherAmount-error"
                                  : null
                                : null
                            }
                            onBlur={(e): void => {
                              if (
                                values.donationAmount.otherAmount &&
                                values.donationAmount.otherAmount > 0
                              ) {
                                form.setFieldValue(
                                  field.name,
                                  parseFloat(
                                    values.donationAmount.otherAmount
                                  ).toFixed(2)
                                );
                                form.setFieldTouched(field.name, true, false);
                              } else {
                                handleBlur(e);
                              }
                            }}
                            onChange={(e): void => {
                              if (e.target.value.length > 0) {
                                const roundedValue =
                                  /^-?\d+(?:\.\d{0,2})?/.exec(e.target.value)
                                    ? /^-?\d+(?:\.\d{0,2})?/.exec(
                                        e.target.value
                                      )[0]
                                    : e.target.value;
                                roundedValue
                                  ? form.setFieldValue(field.name, roundedValue)
                                  : form.setFieldValue(
                                      field.name,
                                      e.target.value
                                    );

                                nudges?.includes(Number(e.target.value))
                                  ? form.setFieldValue(
                                      "donationAmount.amountRadioGroup",
                                      parseInt(e.target.value, 10).toString(10)
                                    )
                                  : form.setFieldValue(
                                      "donationAmount.amountRadioGroup",
                                      ""
                                    );
                              } else {
                                handleChange(e);
                              }
                            }}
                          />
                          <span id="amountCurrency" className="textField__icon">
                            &pound;
                          </span>
                        </TextFieldWrapper>
                      )}
                    </Field>
                  </OtherAmountDiv>
                </Fields>
                {touched.donationAmount && errors.donationAmount ? (
                  <div role="alert" id="donationAmount-error">
                    <FieldErrorMessage>
                      {errors.donationAmount.amountRadioGroup ? (
                        errors.donationAmount.amountRadioGroup
                      ) : (
                        <span id="otherAmount-error">
                          {errors.donationAmount.otherAmount}
                        </span>
                      )}
                    </FieldErrorMessage>
                  </div>
                ) : (
                  ""
                )}
              </fieldset>
              {formVersion === "regular" ? (
                <fieldset
                  aria-invalid={
                    errors.donationfrequency && touched.donationfrequency
                      ? "true"
                      : null
                  }
                  aria-describedby={
                    errors.donationfrequency && touched.donationfrequency
                      ? "donationfrequency-error"
                      : null
                  }
                >
                  <legend>
                    <RequiredParagraph id="frequencyLabel">
                      Tell us how often you&rsquo;d like to donate{" "}
                      <span>(required)</span>
                    </RequiredParagraph>
                  </legend>
                  <RadioButtonGroup
                    formTheme={formTheme}
                    id="frequency"
                    role="radiogroup"
                    aria-required="true"
                    aria-labelledby="frequencyLabel"
                  >
                    {donationFrequencies.map((frequency: string) => (
                      <div key={`frequency-${frequency}`}>
                        <Field name="frequency">
                          {({ field }: FieldProps): ReactElement => (
                            <input
                              {...field}
                              id={`frequency-${frequency}`}
                              type="radio"
                              value={frequency}
                              checked={frequency === checkedfrequency}
                              onBlur={(e): void => {
                                handleBlur(e);
                              }}
                              onChange={(e): void => {
                                handleChange(e);
                                setDonationData(donationData);
                              }}
                            />
                          )}
                        </Field>
                        <label htmlFor={`frequency-${frequency}`}>
                          {frequency}
                        </label>
                      </div>
                    ))}
                  </RadioButtonGroup>
                  {touched.frequency && errors.frequency && (
                    <div role="alert" id="donationFrequency-error">
                      <FieldErrorMessage>{errors.frequency}</FieldErrorMessage>
                    </div>
                  )}
                </fieldset>
              ) : (
                ""
              )}
              {showButton ? (
                <Box marginTop="xs">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      const href = `?${buttonUrl}`;

                      const optimizelyEvent =
                        formVersion === "single"
                          ? "switch_from_single_to_regular"
                          : "switch_from_regular_to_single";
                      user?.trackEvent(optimizelyEvent);

                      window.location.href = href;
                    }}
                    href={`?${buttonUrl}`}
                    appearance="primary"
                  >
                    Make a {buttonText} donation instead
                  </Link>
                </Box>
              ) : (
                ""
              )}
            </FormFields>
          </FormGroup>
        }
      >
        {(variation) =>
          variation === "condensed_view" && showCondensed ? (
            <FormGroup>
              <CondensedAmountSection>
                <Heading h2>Your regular donation</Heading>
                <CondensedViewContainer>
                  <CondensedViewIcons>
                    <Heading h3 as="p" marginBottom="none">
                      <CalendarIcon
                        src="/assets/calendar.svg"
                        alt="Calendar icon"
                      />
                      <span>
                        {lowercaseFrequency[0].toUpperCase() +
                          lowercaseFrequency.slice(1)}
                      </span>
                    </Heading>
                    <DDLogo
                      src="/assets/direct-debit.svg"
                      alt="Direct Debit logo"
                    />
                  </CondensedViewIcons>
                  <Heading h2 as="p" marginTop="xs">
                    £{formatAmount}
                  </Heading>
                  <CondensedViewButton
                    onClick={(e) => {
                      e.preventDefault();
                      user?.trackEvent(
                        "condensed_amount_view_edit_button_click"
                      );
                      setShowCondensed(false);
                    }}
                    appearance="tertiary"
                  >
                    {lowercaseFrequency === "one-off"
                      ? "Edit amount"
                      : "Edit amount or frequency"}
                  </CondensedViewButton>
                </CondensedViewContainer>
                {showButton ? (
                  <Box marginTop="xs">
                    Or{" "}
                    <CondensedViewLink
                      onClick={() => {
                        const href = `?${buttonUrl}`;

                        const optimizelyEvent =
                          formVersion === "single"
                            ? "switch_from_single_to_regular"
                            : "switch_from_regular_to_single";
                        user?.trackEvent(optimizelyEvent);

                        window.location.href = href;
                      }}
                      href={`?${buttonUrl}`}
                      appearance="primary"
                    >
                      make a {buttonText} donation instead
                    </CondensedViewLink>
                  </Box>
                ) : (
                  ""
                )}
              </CondensedAmountSection>
            </FormGroup>
          ) : (
            <FormGroup>
              <FormFields>
                <fieldset
                  tabIndex={-1}
                  aria-invalid={
                    errors.donationAmount && touched.donationAmount
                      ? "true"
                      : null
                  }
                  aria-describedby={
                    errors.donationAmount && touched.donationAmount
                      ? "donationAmount-error"
                      : null
                  }
                >
                  <legend>
                    <Heading h2>Donation amount</Heading>
                    <RequiredParagraph id="amountRadioGroupLabel">
                      Please {nudges ? "choose" : "enter"} an amount for your{" "}
                      {formVersion === "regular" ? "regular" : ""} donation.{" "}
                      <span>(required)</span>
                    </RequiredParagraph>
                  </legend>
                  <Fields>
                    {nudges && nudges.length > 0 ? (
                      <RadioButtonGroup
                        formTheme={formTheme}
                        id="amountRadioGroup"
                        role="radiogroup"
                        aria-required="true"
                        aria-labelledby="amountRadioGroupLabel"
                      >
                        {nudges.map((nudge: number) => {
                          const amount = nudge.toString(10);

                          return (
                            <div key={`amount${amount}`}>
                              <Field name="donationAmount.amountRadioGroup">
                                {({
                                  field,
                                  form,
                                }: FieldProps): ReactElement => (
                                  <input
                                    {...field}
                                    id={`amount${amount}`}
                                    type="radio"
                                    value={amount}
                                    data-cy={`amount-sel-${amount}`}
                                    checked={amount === checkedAmount}
                                    onBlur={(e): void => {
                                      handleBlur(e);
                                    }}
                                    onChange={(e): void => {
                                      form.setFieldValue(
                                        "donationAmount.otherAmount",
                                        ""
                                      );
                                      handleChange(e);
                                      setDonationData(donationData);
                                    }}
                                  />
                                )}
                              </Field>
                              <label htmlFor={`amount${amount}`}>
                                £<span>{amount}</span>
                              </label>
                            </div>
                          );
                        })}
                      </RadioButtonGroup>
                    ) : (
                      ""
                    )}
                    <OtherAmountDiv extraTopSpacing={!nudges}>
                      <Field name="donationAmount.otherAmount">
                        {({ field, form }: FieldProps): ReactElement => (
                          <TextFieldWrapper>
                            <TextField
                              id="otherAmount"
                              data-testid="otherAmount"
                              value={values.donationAmount.otherAmount}
                              name={field.name}
                              label={nudges ? "Other amount" : "Amount"}
                              extraLeft="£"
                              aria-invalid={
                                errors.donationAmount && touched.donationAmount
                                  ? errors.donationAmount.otherAmount &&
                                    touched.donationAmount.otherAmount
                                    ? "true"
                                    : null
                                  : null
                              }
                              aria-describedby={
                                errors.donationAmount && touched.donationAmount
                                  ? errors.donationAmount.otherAmount &&
                                    touched.donationAmount.otherAmount
                                    ? "otherAmount-error"
                                    : null
                                  : null
                              }
                              onBlur={(e): void => {
                                if (
                                  values.donationAmount.otherAmount &&
                                  values.donationAmount.otherAmount > 0
                                ) {
                                  form.setFieldValue(
                                    field.name,
                                    parseFloat(
                                      values.donationAmount.otherAmount
                                    ).toFixed(2)
                                  );
                                  form.setFieldTouched(field.name, true, false);
                                } else {
                                  handleBlur(e);
                                }
                              }}
                              onChange={(e): void => {
                                if (e.target.value.length > 0) {
                                  const roundedValue =
                                    /^-?\d+(?:\.\d{0,2})?/.exec(e.target.value)
                                      ? /^-?\d+(?:\.\d{0,2})?/.exec(
                                          e.target.value
                                        )[0]
                                      : e.target.value;
                                  roundedValue
                                    ? form.setFieldValue(
                                        field.name,
                                        roundedValue
                                      )
                                    : form.setFieldValue(
                                        field.name,
                                        e.target.value
                                      );

                                  nudges?.includes(Number(e.target.value))
                                    ? form.setFieldValue(
                                        "donationAmount.amountRadioGroup",
                                        parseInt(e.target.value, 10).toString(
                                          10
                                        )
                                      )
                                    : form.setFieldValue(
                                        "donationAmount.amountRadioGroup",
                                        ""
                                      );
                                } else {
                                  handleChange(e);
                                }
                              }}
                            />
                            <span
                              id="amountCurrency"
                              className="textField__icon"
                            >
                              &pound;
                            </span>
                          </TextFieldWrapper>
                        )}
                      </Field>
                    </OtherAmountDiv>
                  </Fields>
                  {touched.donationAmount && errors.donationAmount ? (
                    <div role="alert" id="donationAmount-error">
                      <FieldErrorMessage>
                        {errors.donationAmount.amountRadioGroup ? (
                          errors.donationAmount.amountRadioGroup
                        ) : (
                          <span id="otherAmount-error">
                            {errors.donationAmount.otherAmount}
                          </span>
                        )}
                      </FieldErrorMessage>
                    </div>
                  ) : (
                    ""
                  )}
                </fieldset>
                {formVersion === "regular" ? (
                  <fieldset
                    aria-invalid={
                      errors.donationfrequency && touched.donationfrequency
                        ? "true"
                        : null
                    }
                    aria-describedby={
                      errors.donationfrequency && touched.donationfrequency
                        ? "donationfrequency-error"
                        : null
                    }
                  >
                    <legend>
                      <RequiredParagraph id="frequencyLabel">
                        Tell us how often you&rsquo;d like to donate{" "}
                        <span>(required)</span>
                      </RequiredParagraph>
                    </legend>
                    <RadioButtonGroup
                      formTheme={formTheme}
                      id="frequency"
                      role="radiogroup"
                      aria-required="true"
                      aria-labelledby="frequencyLabel"
                    >
                      {donationFrequencies.map((frequency: string) => (
                        <div key={`frequency-${frequency}`}>
                          <Field name="frequency">
                            {({ field }: FieldProps): ReactElement => (
                              <input
                                {...field}
                                id={`frequency-${frequency}`}
                                type="radio"
                                value={frequency}
                                checked={frequency === checkedfrequency}
                                onBlur={(e): void => {
                                  handleBlur(e);
                                }}
                                onChange={(e): void => {
                                  handleChange(e);
                                  setDonationData(donationData);
                                }}
                              />
                            )}
                          </Field>
                          <label htmlFor={`frequency-${frequency}`}>
                            {frequency}
                          </label>
                        </div>
                      ))}
                    </RadioButtonGroup>
                    {touched.frequency && errors.frequency && (
                      <div role="alert" id="donationFrequency-error">
                        <FieldErrorMessage>
                          {errors.frequency}
                        </FieldErrorMessage>
                      </div>
                    )}
                  </fieldset>
                ) : (
                  ""
                )}
                {showButton ? (
                  <Box marginTop="xs">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        const href = `?${buttonUrl}`;

                        const optimizelyEvent =
                          formVersion === "single"
                            ? "switch_from_single_to_regular"
                            : "switch_from_regular_to_single";
                        user?.trackEvent(optimizelyEvent);

                        window.location.href = href;
                      }}
                      href={`?${buttonUrl}`}
                      appearance="primary"
                    >
                      Make a {buttonText} donation instead
                    </Link>
                  </Box>
                ) : (
                  ""
                )}
              </FormFields>
            </FormGroup>
          )
        }
      </OptimizelyExperiment>
    );
  }

  return (
    <FormGroup>
      <FormFields>
        <fieldset
          tabIndex={-1}
          aria-invalid={
            errors.donationAmount && touched.donationAmount ? "true" : null
          }
          aria-describedby={
            errors.donationAmount && touched.donationAmount
              ? "donationAmount-error"
              : null
          }
        >
          <legend>
            <Heading h2>Donation amount</Heading>
            <RequiredParagraph id="amountRadioGroupLabel">
              Please {nudges ? "choose" : "enter"} an amount for your{" "}
              {formVersion === "regular" ? "regular" : ""} donation.{" "}
              <span>(required)</span>
            </RequiredParagraph>
          </legend>
          <Fields>
            {nudges && nudges.length > 0 ? (
              <RadioButtonGroup
                formTheme={formTheme}
                id="amountRadioGroup"
                role="radiogroup"
                aria-required="true"
                aria-labelledby="amountRadioGroupLabel"
              >
                {nudges.map((nudge: number) => {
                  const amount = nudge.toString(10);

                  return (
                    <div key={`amount${amount}`}>
                      <Field name="donationAmount.amountRadioGroup">
                        {({ field, form }: FieldProps): ReactElement => (
                          <input
                            {...field}
                            id={`amount${amount}`}
                            type="radio"
                            value={amount}
                            data-cy={`amount-sel-${amount}`}
                            checked={amount === checkedAmount}
                            onBlur={(e): void => {
                              handleBlur(e);
                            }}
                            onChange={(e): void => {
                              form.setFieldValue(
                                "donationAmount.otherAmount",
                                ""
                              );
                              handleChange(e);
                              setDonationData(donationData);
                            }}
                          />
                        )}
                      </Field>
                      <label htmlFor={`amount${amount}`}>
                        £<span>{amount}</span>
                      </label>
                    </div>
                  );
                })}
              </RadioButtonGroup>
            ) : (
              ""
            )}
            <OtherAmountDiv extraTopSpacing={!nudges}>
              <Field name="donationAmount.otherAmount">
                {({ field, form }: FieldProps): ReactElement => (
                  <TextFieldWrapper>
                    <TextField
                      id="otherAmount"
                      data-testid="otherAmount"
                      value={values.donationAmount.otherAmount}
                      name={field.name}
                      label={nudges ? "Other amount" : "Amount"}
                      extraLeft="£"
                      aria-invalid={
                        errors.donationAmount && touched.donationAmount
                          ? errors.donationAmount.otherAmount &&
                            touched.donationAmount.otherAmount
                            ? "true"
                            : null
                          : null
                      }
                      aria-describedby={
                        errors.donationAmount && touched.donationAmount
                          ? errors.donationAmount.otherAmount &&
                            touched.donationAmount.otherAmount
                            ? "otherAmount-error"
                            : null
                          : null
                      }
                      onBlur={(e): void => {
                        if (
                          values.donationAmount.otherAmount &&
                          values.donationAmount.otherAmount > 0
                        ) {
                          form.setFieldValue(
                            field.name,
                            parseFloat(
                              values.donationAmount.otherAmount
                            ).toFixed(2)
                          );
                          form.setFieldTouched(field.name, true, false);
                        } else {
                          handleBlur(e);
                        }
                      }}
                      onChange={(e): void => {
                        if (e.target.value.length > 0) {
                          const roundedValue = /^-?\d+(?:\.\d{0,2})?/.exec(
                            e.target.value
                          )
                            ? /^-?\d+(?:\.\d{0,2})?/.exec(e.target.value)[0]
                            : e.target.value;
                          roundedValue
                            ? form.setFieldValue(field.name, roundedValue)
                            : form.setFieldValue(field.name, e.target.value);

                          nudges?.includes(Number(e.target.value))
                            ? form.setFieldValue(
                                "donationAmount.amountRadioGroup",
                                parseInt(e.target.value, 10).toString(10)
                              )
                            : form.setFieldValue(
                                "donationAmount.amountRadioGroup",
                                ""
                              );
                        } else {
                          handleChange(e);
                        }
                      }}
                    />
                    <span id="amountCurrency" className="textField__icon">
                      &pound;
                    </span>
                  </TextFieldWrapper>
                )}
              </Field>
            </OtherAmountDiv>
          </Fields>
          {touched.donationAmount && errors.donationAmount ? (
            <div role="alert" id="donationAmount-error">
              <FieldErrorMessage>
                {errors.donationAmount.amountRadioGroup ? (
                  errors.donationAmount.amountRadioGroup
                ) : (
                  <span id="otherAmount-error">
                    {errors.donationAmount.otherAmount}
                  </span>
                )}
              </FieldErrorMessage>
            </div>
          ) : (
            ""
          )}
        </fieldset>
        {formVersion === "regular" ? (
          <fieldset
            aria-invalid={
              errors.donationfrequency && touched.donationfrequency
                ? "true"
                : null
            }
            aria-describedby={
              errors.donationfrequency && touched.donationfrequency
                ? "donationfrequency-error"
                : null
            }
          >
            <legend>
              <RequiredParagraph id="frequencyLabel">
                Tell us how often you&rsquo;d like to donate{" "}
                <span>(required)</span>
              </RequiredParagraph>
            </legend>
            <RadioButtonGroup
              formTheme={formTheme}
              id="frequency"
              role="radiogroup"
              aria-required="true"
              aria-labelledby="frequencyLabel"
            >
              {donationFrequencies.map((frequency: string) => (
                <div key={`frequency-${frequency}`}>
                  <Field name="frequency">
                    {({ field }: FieldProps): ReactElement => (
                      <input
                        {...field}
                        id={`frequency-${frequency}`}
                        type="radio"
                        value={frequency}
                        checked={frequency === checkedfrequency}
                        onBlur={(e): void => {
                          handleBlur(e);
                        }}
                        onChange={(e): void => {
                          handleChange(e);
                          setDonationData(donationData);
                        }}
                      />
                    )}
                  </Field>
                  <label htmlFor={`frequency-${frequency}`}>{frequency}</label>
                </div>
              ))}
            </RadioButtonGroup>
            {touched.frequency && errors.frequency && (
              <div role="alert" id="donationFrequency-error">
                <FieldErrorMessage>{errors.frequency}</FieldErrorMessage>
              </div>
            )}
          </fieldset>
        ) : (
          ""
        )}
        {showButton ? (
          <Box marginTop="xs">
            <Link
              onClick={(e) => {
                e.preventDefault();
                const href = `?${buttonUrl}`;

                const optimizelyEvent =
                  formVersion === "single"
                    ? "switch_from_single_to_regular"
                    : "switch_from_regular_to_single";
                user?.trackEvent(optimizelyEvent);

                window.location.href = href;
              }}
              href={`?${buttonUrl}`}
              appearance="primary"
            >
              Make a {buttonText} donation instead
            </Link>
          </Box>
        ) : (
          ""
        )}
      </FormFields>
    </FormGroup>
  );
};

export default AmountSelection;
