import React, { PropsWithChildren, ReactNode, ReactElement } from "react";
import styled from "styled-components";

const ButtonGroupStyled = styled.div`
  > * {
    display: block !important;
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.spacing.xs};

    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
      display: inline-block !important;
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.spacing.s};
    }
  }
`;

const ButtonGroup = ({
  children,
}: PropsWithChildren<{ children?: ReactNode }>): ReactElement => (
  <ButtonGroupStyled>{children}</ButtonGroupStyled>
);

export default ButtonGroup;
