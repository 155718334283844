import { useEffect, ReactElement } from "react";
import { datadogRum } from "@datadog/browser-rum";
import useDataLayer from "../helpers/useDataLayer";
import { FormPropsType } from "../helpers/types/shared";

const FocusError = ({
  errors,
  touched,
  isSubmitting,
  isValidating,
}: FormPropsType): ReactElement | null => {
  const { pushError } = useDataLayer();

  useEffect(() => {
    const sendError = (fieldName: string, fieldError: string) => {
      pushError("", "validation", `${fieldName}: ${fieldError}`);
    };

    const errorFields = Object.keys(errors);
    errorFields.forEach((field) => {
      if (typeof errors[field] !== "object" && touched[field]) {
        sendError(field, errors[field]);
      }
    });
  }, [errors, touched]);

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors);
      if (keys.length > 0 && isSubmitting && !isValidating) {
        pushError("", "navigation", JSON.stringify(errors));
        datadogRum.addError("Navigation error", { errors });

        const focusElements = keys
          .map((key) => ({
            fieldset: document.querySelector<HTMLElement>(
              `fieldset[aria-describedby="${key}-error"]`
            ),
            key,
          }))
          .map(({ fieldset, key }) =>
            fieldset !== null
              ? fieldset
              : document.querySelector<HTMLElement>(`[name="${key}"]`)
          )
          .filter(
            (element) =>
              element !== null &&
              element.offsetWidth > 0 &&
              element.offsetHeight > 0
          )
          .map((element) => ({
            errorElement: element,
            labelElement: document.querySelector<HTMLElement>(
              `label[for="${element.id}"]`
            ),
          }))
          .map(({ errorElement, labelElement }) =>
            labelElement !== null &&
            labelElement.offsetWidth > 0 &&
            labelElement.offsetHeight > 0
              ? { element: labelElement, isField: false }
              : { element: errorElement, isField: true }
          );

        if (focusElements.length > 0) {
          const firstError = focusElements[0];
          if (firstError.isField) {
            firstError.element.focus({ preventScroll: true });
          }
          firstError.element.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusError;
