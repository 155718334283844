// DEPENDENCIES
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import React, { ReactElement, FC, useContext, useEffect } from "react";
import { useRouter } from "next/router";

// CONFIG
import { Button } from "@cruk/cruk-react-components";
import { getFormComponents, hasNudges } from "../../../configs/single-donation";

// SCHEMA
import { DonationAmountFormSchema } from "../../../schema/single-donation";

// CONTEXTS
import FormNameContext from "../../../contexts/FormNameContext";
import DonationContext from "../../../contexts/DonationContext";
import VersionsContext from "../../../contexts/VersionsContext";
import { OptimizelyContext } from "../../../contexts/OptimizelyContext";

// HELPERS
import useOptimizelyTrack from "../../../helpers/useOptimizelyTrack";

// COMPONENTS
import AmountSelection from "../AmountSelection";
import DonationType from "../DonationType";
import DonationMotivation from "../DonationMotivation";
import DonationDestination from "../DonationDestination";
import FocusError from "../../FocusError";
import FormGroup from "../../FormGroup";
import FormFields from "../../FormFields";
import ButtonGroup from "../../ButtonGroup";
import OptimizelyLoader from "../../OptimizelyLoader";
import OptimizelyExperiment from "../../OptimizelyExperiment";

type DonationPageSearchParams = {
  [key: string]: string;
};

const onNavigationSearchQueryHandler = (
  params: Partial<DonationPageSearchParams>
) => {
  const searchParams = new URLSearchParams(window.location.search);

  for (const key of searchParams.keys()) {
    !(key in params) && searchParams.delete(key);
    key in params && searchParams.set(key, params[key]);
  }

  const stringified = searchParams.toString();

  const updatedHistoryState = {
    ...window.history.state,
    as: `${window.location.pathname}?${stringified}`,
  };

  window.history.replaceState(updatedHistoryState, "", `?${stringified}`);
};

const DonationForm: FC = (): ReactElement => {
  const { donationData, setDonationData } = useContext(DonationContext);
  const router = useRouter();
  const formName = useContext(FormNameContext);
  const formComponents = getFormComponents(formName);
  const { formVersion } = useContext(VersionsContext);
  const [optimizelyContext] = useContext(OptimizelyContext);
  const { optimizely, optimizelyUserContext } = optimizelyContext;

  useOptimizelyTrack({
    event:
      formVersion === "single"
        ? "start_single_donation"
        : "start_regular_donation",
    client: optimizely,
    user: optimizelyUserContext,
  });

  useEffect(() => {
    if (!formVersion) return null;
  }, [formVersion]);

  return (
    <Formik
      enableReinitialize
      validateOnChange
      initialValues={donationData}
      onSubmit={async (values): Promise<void> => {
        setDonationData(values);
        if (typeof window !== "undefined") {
          sessionStorage.setItem(
            `formData-${formName}`,
            JSON.stringify(values)
          );
        }

        onNavigationSearchQueryHandler({
          amount: values.donationAmount.amountRadioGroup
            ? values.donationAmount.amountRadioGroup
            : values.donationAmount.otherAmount,
          frequency: values.frequency,
          restriction: values.restriction,
          inmemory:
            values.motivation === "In memory of someone" ? "true" : null,
          personal: values.typeRadioGroup,
        });

        await router.push(`/${formName}/details`);
      }}
      validate={async (values) => {
        try {
          await validateYupSchema(values, DonationAmountFormSchema, true, {
            formVersion,
            hasNudges: hasNudges(formComponents, formVersion),
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        isValidating,
        setFieldValue,
        touched,
      }): ReactElement => (
        <Form>
          {/* TODO Shortened Motivation Section */}
          {formName === "support-us" ? (
            <>
              <FocusError
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <AmountSelection
                values={values}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                setFieldValue={setFieldValue}
              />

              {/* TODO Shortened Motivation Section */}
              {formVersion === "single" ? (
                <OptimizelyExperiment
                  experiment="your_donation_shortened_motivation_field"
                  render={
                    <>
                      <OptimizelyLoader />
                      <DonationType
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        touched={touched}
                      />
                    </>
                  }
                  childrenPositionOnVariant={{
                    shortened_view: "start",
                    original_view: "end",
                    default: "end",
                  }}
                  defaultComponent={
                    formComponents.donationMotivation && (
                      <DonationMotivation
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )
                  }
                >
                  {(variation) =>
                    formComponents.donationMotivation && (
                      <DonationMotivation
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        canExperiment={variation === "shortened_view"}
                      />
                    )
                  }
                </OptimizelyExperiment>
              ) : (
                formComponents.donationMotivation && (
                  <DonationMotivation
                    values={values}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                )
              )}

              {formComponents.donationDestination ? (
                <DonationDestination
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                />
              ) : (
                ""
              )}

              <FormGroup noborder>
                <FormFields>
                  <ButtonGroup>
                    <Button type="submit">Continue</Button>
                  </ButtonGroup>
                </FormFields>
              </FormGroup>
            </>
          ) : (
            <>
              <FocusError
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                isValidating={isValidating}
              />
              <AmountSelection
                values={values}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                setFieldValue={setFieldValue}
              />
              {formVersion === "single" ? (
                <DonationType
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  touched={touched}
                />
              ) : (
                ""
              )}

              {formComponents.donationMotivation ? (
                <DonationMotivation
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              ) : (
                ""
              )}

              {formComponents.donationDestination ? (
                <DonationDestination
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                />
              ) : (
                ""
              )}

              <FormGroup noborder>
                <FormFields>
                  <ButtonGroup>
                    <Button type="submit">Continue</Button>
                  </ButtonGroup>
                </FormFields>
              </FormGroup>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DonationForm;
