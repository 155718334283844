// EXTERNAL
import React, { useState, useContext, useEffect, ReactElement } from "react";
import styled from "styled-components";
import { Heading, Radio, Box } from "@cruk/cruk-react-components";
import { Field, FieldProps } from "formik";

// HELPERS
import useDataLayer from "../../helpers/useDataLayer";
import { FormPropsType } from "../../helpers/types/shared";
import { objectHasVal } from "../../helpers/functions/shared";

// CONTEXT
import FormNameContext from "../../contexts/FormNameContext";
import DonationContext from "../../contexts/DonationContext";

// COMPONENTS
import FormGroup from "../FormGroup";
import FormFields from "../FormFields";
import FieldErrorMessage from "../FieldErrorMessage";

// STYLES
import { RequiredParagraph } from "../styles";

const RadioButtonGroup = styled.div`
  width: 100%;
  padding-top: 10px;
`;

const StyledError = styled.div`
  p {
    display: inline-block;
  }
`;

const DonationType = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  touched,
}: FormPropsType): ReactElement => {
  const [checked, setChecked] = useState(
    values.typeRadioGroup ? values.typeRadioGroup : ""
  );
  const { pushInteraction } = useDataLayer();
  const formName = useContext(FormNameContext);
  const { setDonationData } = useContext(DonationContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const urlDonationType = searchParams.get("personal");
    const validUrlDonationType = objectHasVal(types, "value", urlDonationType);
    const donationValues = values;
    if (validUrlDonationType) {
      donationValues.typeRadioGroup = urlDonationType;
      setChecked(urlDonationType);
    }
    setDonationData(donationValues);
    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        `formData-${formName}`,
        JSON.stringify(donationValues)
      );
    }
  }, []);

  useEffect(() => {
    setChecked(values.typeRadioGroup);
  }, [values.typeRadioGroup]);

  const types = [
    {
      value: "yes",
      label: "I am donating my own money",
    },
    {
      value: "no",
      label: "I am paying in money from fundraising or a collection",
    },
  ];

  return (
    <FormGroup>
      <FormFields>
        <fieldset
          tabIndex={-1}
          aria-invalid={
            errors.typeRadioGroup && touched.typeRadioGroup ? "true" : "false"
          }
          aria-describedby={
            errors.typeRadioGroup && touched.typeRadioGroup
              ? "typeRadioGroup-error"
              : null
          }
        >
          <legend>
            <Heading h2>Donation type</Heading>
            <RequiredParagraph id="typeRadioGroupLabel">
              Please choose your donation type. <span>(required)</span>
            </RequiredParagraph>
          </legend>
          <RadioButtonGroup
            id="typeRadioGroup"
            role="radiogroup"
            aria-required="true"
            aria-labelledby="typeRadioGroupLabel"
          >
            {types.map((type, index) => (
              <Field key={`type${type.value}`} name="typeRadioGroup">
                {({ field }: FieldProps): ReactElement => {
                  const hasError: boolean = errors[field.name] !== undefined;
                  const fieldTouched = touched[field.name];
                  return (
                    <Box marginBottom={index === 0 ? "xs" : null}>
                      <Radio
                        id={`${field.name}${index}`}
                        name={field.name}
                        value={type.value}
                        checked={type.value === checked}
                        hasError={fieldTouched && hasError}
                        onChange={(e): void => {
                          setFieldValue("giftAid", false);
                          pushInteraction(
                            "Donation type",
                            type.value === "yes" ? "personal" : "offline"
                          );
                          handleChange(e);
                        }}
                      >
                        {type.label}
                      </Radio>
                    </Box>
                  );
                }}
              </Field>
            ))}
          </RadioButtonGroup>
          {errors.typeRadioGroup && touched.typeRadioGroup && (
            <StyledError role="alert" id="typeRadioGroup-error">
              <FieldErrorMessage>{errors.typeRadioGroup}</FieldErrorMessage>
            </StyledError>
          )}

          <p>We can only claim Gift Aid on donations of your own money.</p>
          <p>
            Your donation is not eligible for Gift Aid if you received anything
            in return for it or raised it from your own fundraising.
          </p>
        </fieldset>
      </FormFields>
    </FormGroup>
  );
};

export default DonationType;
