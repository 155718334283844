import { useContext } from "react";
import { Loader } from "@cruk/cruk-react-components";
import { OptimizelyContext } from "../contexts/OptimizelyContext";

type OptimizelyLoaderProps = {
  children?: JSX.Element;
  loaderComponent?: JSX.Element;
};

const OptimizelyLoader = ({
  children = null,
  loaderComponent = <Loader />,
}: OptimizelyLoaderProps) => {
  const [optimizelyContext] = useContext(OptimizelyContext);
  const { loadingState } = optimizelyContext;

  return loadingState === "loading" ? loaderComponent : children;
};

export default OptimizelyLoader;
