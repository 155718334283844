// EXTERNAL
import React, { useState, useContext, useEffect, ReactElement } from "react";
import styled from "styled-components";
import { Field, FieldProps } from "formik";
import { Heading, Box, Radio, Select } from "@cruk/cruk-react-components";
// UTILITIES
import useDataLayer from "../../helpers/useDataLayer";
import { FormPropsType } from "../../helpers/types/shared";
// CONTEXT
import FormNameContext from "../../contexts/FormNameContext";
import DonationContext from "../../contexts/DonationContext";
// HELPERS
import { objectHasVal } from "../../helpers/functions/shared";
// COMPONENTS
import FormGroup from "../FormGroup";
import FormFields from "../FormFields";
import GetSelectOptions from "../GetSelectOptions";
import ErrorBox from "../ErrorBox";
import PhoneNumber from "../PhoneNumber";
// STYLES
import { RequiredParagraph, ExtendedBoxWrapper } from "../styles";
// DATA
import researchAreas from "../../data/researchAreas";
// CONFIG
import { getFormComponents } from "../../configs/single-donation";

const RadioButtonGroup = styled.div`
  width: 100%;
`;

const types = [
  {
    value: "greatest",
    label: "Where the need is greatest",
  },
  {
    value: "choose",
    label: "Choose a cancer type or an area of research",
  },
];

const DonationDestination = ({
  values,
  errors,
  handleBlur,
  handleChange,
  touched,
}: FormPropsType): ReactElement => {
  const [checked, setChecked] = useState(
    values.destinationRadioGroup ? values.destinationRadioGroup : ""
  );
  const [areaSelection, setAreaSelection] = useState(
    values.restriction ? values.restriction : ""
  );
  const { pushInteraction, pushError } = useDataLayer();
  const areaSelectionLabel = "Select a cancer type or research area";
  const { setDonationData } = useContext(DonationContext);
  const formName = useContext(FormNameContext);
  const formComponents = getFormComponents(formName);

  useEffect(() => {
    if (formComponents.donationDestination === true) {
      const searchParams = new URLSearchParams(window.location.search);
      const urlRestriction = searchParams.get("restriction");
      const donationValues = values;
      const validUrlRestriction = objectHasVal(
        researchAreas,
        "value",
        urlRestriction
      );

      if (
        validUrlRestriction &&
        urlRestriction &&
        urlRestriction !== "Unlisted" &&
        !formComponents.restriction // Do not override restriction from config if it exists
      ) {
        // & urlRestriction contains
        donationValues.destinationRadioGroup = "choose";
        donationValues.restriction = urlRestriction;
        setAreaSelection(urlRestriction);
      }
      setDonationData(donationValues);
      if (typeof window !== "undefined") {
        sessionStorage.setItem(
          `formData-${formName}`,
          JSON.stringify(donationValues)
        );
      }
    }
  }, []);

  useEffect(() => {
    setChecked(values.destinationRadioGroup);
  }, [values.destinationRadioGroup]);

  return (
    <FormGroup>
      <FormFields>
        <fieldset
          tabIndex={-1}
          aria-invalid={
            errors.destinationRadioGroup && touched.destinationRadioGroup
              ? "true"
              : "false"
          }
          aria-describedby={
            errors.destinationRadioGroup && touched.destinationRadioGroup
              ? "destinationRadioGroup-error"
              : null
          }
        >
          <legend>
            <Heading h2>Where your donation goes</Heading>
          </legend>

          <RequiredParagraph id="destinationRadioGroupLabel">
            Choose how you would like your donation to fund our work.
          </RequiredParagraph>

          <RadioButtonGroup id="destinationRadioGroup">
            {types.map((type, index) => (
              <Field key={`type${type.value}`} name="destinationRadioGroup">
                {({ field, form }: FieldProps): ReactElement => (
                  <Box marginBottom={index === 0 ? "xs" : null}>
                    <Radio
                      id={`${field.name}${index}`}
                      data-testid={`${field.name}${index}`}
                      name={field.name}
                      value={type.value}
                      checked={type.value === checked}
                      aria-label={type.label}
                      onChange={(e): void => {
                        pushInteraction("Donation destination", type.value);
                        handleChange(e);
                        form.setFieldValue("restriction", "");
                        setAreaSelection("");
                      }}
                    >
                      {type.label}
                    </Radio>
                  </Box>
                )}
              </Field>
            ))}
          </RadioButtonGroup>

          {checked === "choose" ? (
            <Field name="restriction">
              {({ field, form }: FieldProps): ReactElement => {
                const fieldError = errors[field.name] as string;

                return (
                  <ExtendedBoxWrapper marginTop="xs">
                    <Select
                      aria-label={areaSelectionLabel}
                      data-testid="restrictionSelect"
                      value={areaSelection}
                      label=""
                      name={field.name}
                      hasError={errors[field.name] !== undefined}
                      errorMessage={errors[field.name]}
                      onBlur={(e): void => {
                        handleBlur(e);
                        if (errors[field.name])
                          pushError(
                            "",
                            "validation",
                            `${field.name}: ${fieldError}`
                          );
                      }}
                      onChange={(e): void => {
                        if (e.target.value === "Unlisted") {
                          form.setFieldValue(
                            "destinationRadioGroup",
                            "greatest"
                          );
                          form.setFieldValue("restriction", "");
                        } else {
                          handleChange(e);
                        }
                        pushInteraction("Donation destination", e.target.value);
                        setAreaSelection(e.target.value);
                      }}
                    >
                      <GetSelectOptions options={researchAreas} />
                    </Select>
                  </ExtendedBoxWrapper>
                );
              }}
            </Field>
          ) : (
            ""
          )}
          {areaSelection === "Unlisted" ? (
            <ErrorBox role="region" aria-live="polite" marginTop="xs">
              <p>
                We&rsquo;re not able to list all areas of research here, if you
                can&rsquo;t find what you&rsquo;re looking for please call us on{" "}
                <PhoneNumber>0300 123 1022</PhoneNumber> to complete your
                donation.
              </p>
            </ErrorBox>
          ) : (
            ""
          )}
        </fieldset>
      </FormFields>
    </FormGroup>
  );
};

export default DonationDestination;
