import { SelectOptionsType } from "../../helpers/types/shared";

interface SelectOptionsProps {
  options: Array<SelectOptionsType>;
  defaultSelectOption?: string;
}

const GetSelectOptions = ({
  options,
  defaultSelectOption,
}: SelectOptionsProps): React.ReactElement =>
  options ? (
    <>
      <option value="">{defaultSelectOption || "Please select"}</option>
      {options.map((option: SelectOptionsType) => (
        <option
          key={`select-${option.value}`}
          value={option.value}
          data-option={option.data ? option.data : ""}
        >
          {option.label}
        </option>
      ))}
    </>
  ) : null;

export default GetSelectOptions;
