// EXTERNAL
import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Field, FieldProps } from "formik";
import {
  Box,
  Collapse,
  Text,
  Heading,
  Select,
  TextField,
  Radio,
} from "@cruk/cruk-react-components";
import styled from "styled-components";
// UTILITIES
import useDataLayer from "../../helpers/useDataLayer";
import { FormPropsType } from "../../helpers/types/shared";
// CONTEXT
import FormNameContext from "../../contexts/FormNameContext";
import DonationContext from "../../contexts/DonationContext";
import { OptimizelyContext } from "../../contexts/OptimizelyContext";
// COMPONENTS
import FormGroup from "../FormGroup";
import FormFields from "../FormFields";
import GetSelectOptions from "../GetSelectOptions";
// STYLES
import { InputsLayoutWrapper, StyledCollapse } from "../styles";
// DATA
import motivations from "../../data/motivations";
// CONFIG
import { getFormComponents } from "../../configs/single-donation";

// Optimizely experiment styles
const MotivationRadioGroup = styled.div`
  & > span {
    font-weight: 700;
  }

  & > div:first-of-type {
    display: flex;
    gap: 1rem;
    width: 70%;
    padding: 1rem 0;
  }

  & > div:last-of-type {
    margin-bottom: 0.5rem;
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }
`;

const ScreenReaderOnly = styled.legend`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin-bottom: -1px;
  margin-right: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const ReducedPaddingFormGroup = styled.div`
  max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
  margin: 0 auto;
  padding: 0 0 ${({ theme }) => theme.spacing.m} 0;
  border-bottom: 1px solid ${({ theme }) => theme.tokenColors.grey_900};
  border-top: 4px solid white;
  margin-top: -2px;
`;

const MotivationRadio = ({
  label,
  motivation,
  resetMotivation,
  children,
}: {
  label: string;
  motivation: string;
  resetMotivation: () => void;
  children: ReactNode;
}): ReactElement => {
  const [option, setOption] = useState<"yes" | "no">(
    motivation === "" ? "no" : "yes"
  );

  const [optimizelyContext] = useContext(OptimizelyContext);
  const { optimizelyUserContext: user } = optimizelyContext;

  useEffect(() => {
    setOption(motivation === "" ? "no" : "yes");
  }, [motivation]);

  useEffect(() => {
    if (option === "yes") {
      user?.trackEvent("shortened_motivation_field_yes_click");
    }
  }, [option]);

  return (
    <MotivationRadioGroup>
      <span>{label}</span>
      <div>
        <Radio
          name="show-hide-motivation-section"
          value="no"
          checked={option === "no"}
          onChange={() => {
            setOption("no");
            resetMotivation();
          }}
        >
          No
        </Radio>
        <Radio
          name="show-hide-motivation-section"
          value="yes"
          checked={option === "yes"}
          onChange={() => setOption("yes")}
        >
          Yes
        </Radio>
      </div>
      <div className={option === "yes" ? "show" : "hide"}>{children}</div>
    </MotivationRadioGroup>
  );
};

type DonationMotivationProps = FormPropsType & {
  canExperiment?: boolean;
};

const DonationMotivation = ({
  values,
  errors,
  handleBlur,
  handleChange,
  canExperiment,
}: DonationMotivationProps): ReactElement => {
  const [motivationSelection, setMotivationSelection] = useState(
    values.motivation ? values.motivation : ""
  );
  const { pushInteraction } = useDataLayer();
  const { setDonationData } = useContext(DonationContext);
  const formName = useContext(FormNameContext);
  const formComponents = getFormComponents(formName);

  type MotivationSelectionObjectType = {
    inputLabel: string;
    fieldName: string;
  };

  interface MotivationSelectionDetailsType {
    [key: string]: MotivationSelectionObjectType;
  }

  const motivationSelectionSelectDetails: MotivationSelectionDetailsType = {
    "In memory of someone": {
      inputLabel:
        "If you’d like to dedicate your donation in memory of someone, please enter their name",
      fieldName: "inMemoryName",
    },
    "Different reason": {
      inputLabel: "If you'd like to, please enter your reason for donating",
      fieldName: "otherDonationMotivation",
    },
  };

  const showMotivationSelectionTextField = [
    "Different reason",
    "In memory of someone",
  ].includes(motivationSelection);
  const motivationSelectionFieldName =
    motivationSelectionSelectDetails?.[motivationSelection]?.fieldName;
  const motivationSelectioninputLabel =
    motivationSelectionSelectDetails?.[motivationSelection]?.inputLabel;
  const motivationSelectionLabel =
    "Would you like to tell us why you’re making a donation?";

  useEffect(() => {
    if (formComponents.donationMotivation === true) {
      const searchParams = new URLSearchParams(window.location.search);
      const urlMotivation = searchParams.get("inmemory");
      const donationValues = values;
      if (urlMotivation === "true") {
        donationValues.motivation = "In memory of someone";
        setMotivationSelection("In memory of someone");
      }
      setDonationData(donationValues);
      if (typeof window !== "undefined") {
        sessionStorage.setItem(
          `formData-${formName}`,
          JSON.stringify(donationValues)
        );
      }
    }
  }, []);

  if (canExperiment) {
    return (
      <ReducedPaddingFormGroup>
        <FormFields>
          <fieldset>
            <ScreenReaderOnly>
              <h2>Your motivation</h2>
            </ScreenReaderOnly>

            <Field name="motivation">
              {({ field, form }: FieldProps): ReactElement => {
                const fieldError = errors[field.name] as string;

                return (
                  <MotivationRadio
                    label={motivationSelectionLabel}
                    motivation={motivationSelection}
                    resetMotivation={() => {
                      form.setFieldValue(field.name, "");
                      form.setFieldValue("inMemoryName", "");
                      form.setFieldValue("otherDonationMotivation", "");
                      setMotivationSelection("");
                    }}
                  >
                    <InputsLayoutWrapper>
                      <Select
                        value={motivationSelection}
                        data-testid="selectMotivation"
                        name={field.name}
                        label={null}
                        aria-label={motivationSelectionLabel}
                        hasError={fieldError !== undefined}
                        errorMessage={fieldError}
                        onBlur={(e): void => {
                          handleBlur(e);
                        }}
                        onChange={(e): void => {
                          pushInteraction(
                            "Donation motivation",
                            e.target.value
                          );
                          form.setFieldValue(field.name, e.target.value);
                          form.setFieldValue("inMemoryName", "");
                          form.setFieldValue("otherDonationMotivation", "");
                          setMotivationSelection(e.target.value);
                        }}
                      >
                        <GetSelectOptions options={motivations} />
                      </Select>
                    </InputsLayoutWrapper>
                  </MotivationRadio>
                );
              }}
            </Field>

            <StyledCollapse>
              <Collapse
                headerTitleText="Why are we asking this?"
                id="motivationCollapse"
                onOpenChange={(isOpen: boolean): void => {
                  pushInteraction(
                    "Accordion: Donation motivation",
                    isOpen ? "Open" : "Close"
                  );
                }}
              >
                <Box paddingVertical="xs">
                  <Text>
                    We may use this information to tailor messages, or to send
                    you information which may be helpful to you. We will keep
                    this information confidential, but you do not need to
                    provide a response if you’re not comfortable.
                  </Text>
                </Box>
              </Collapse>
            </StyledCollapse>

            {showMotivationSelectionTextField && (
              <Box paddingTop="xs">
                <Field name={motivationSelectionFieldName}>
                  {({ field }: FieldProps): ReactElement => {
                    const fieldName = field.name;
                    const fieldError = errors[fieldName] as string;
                    const hasError = fieldError !== undefined;
                    const fieldValueEmpty = values[fieldName].length === 0;
                    return (
                      <InputsLayoutWrapper>
                        <TextField
                          id={fieldName}
                          label={motivationSelectioninputLabel}
                          data-testid={fieldName}
                          hasError={hasError}
                          errorMessage={errors[fieldName]}
                          value={values[fieldName]}
                          name={fieldName}
                          onChange={(e): void => {
                            handleChange(e);
                          }}
                          isValidVisible={!fieldValueEmpty && !hasError}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />
                      </InputsLayoutWrapper>
                    );
                  }}
                </Field>
              </Box>
            )}
          </fieldset>
        </FormFields>
      </ReducedPaddingFormGroup>
    );
  }

  return (
    <FormGroup>
      <FormFields>
        <fieldset>
          <legend>
            <Heading h2>Your motivation</Heading>
          </legend>

          <Field name="motivation">
            {({ field, form }: FieldProps): ReactElement => {
              const fieldError = errors[field.name] as string;

              return (
                <InputsLayoutWrapper>
                  <Select
                    value={motivationSelection}
                    data-testid="selectMotivation"
                    name={field.name}
                    label={motivationSelectionLabel}
                    aria-label={motivationSelectionLabel}
                    hasError={fieldError !== undefined}
                    errorMessage={fieldError}
                    onBlur={(e): void => {
                      handleBlur(e);
                    }}
                    onChange={(e): void => {
                      pushInteraction("Donation motivation", e.target.value);
                      form.setFieldValue(field.name, e.target.value);
                      form.setFieldValue("inMemoryName", "");
                      form.setFieldValue("otherDonationMotivation", "");
                      setMotivationSelection(e.target.value);
                    }}
                  >
                    <GetSelectOptions options={motivations} />
                  </Select>
                </InputsLayoutWrapper>
              );
            }}
          </Field>

          <StyledCollapse>
            <Collapse
              headerTitleText="Why are we asking this?"
              id="motivationCollapse"
              onOpenChange={(isOpen: boolean): void => {
                pushInteraction(
                  "Accordion: Donation motivation",
                  isOpen ? "Open" : "Close"
                );
              }}
            >
              <Box paddingVertical="xs">
                <Text>
                  We may use this information to tailor messages, or to send you
                  information which may be helpful to you. We will keep this
                  information confidential, but you do not need to provide a
                  response if you’re not comfortable.
                </Text>
              </Box>
            </Collapse>
          </StyledCollapse>

          {showMotivationSelectionTextField && (
            <Box paddingTop="xs">
              <Field name={motivationSelectionFieldName}>
                {({ field }: FieldProps): ReactElement => {
                  const fieldName = field.name;
                  const fieldError = errors[fieldName] as string;
                  const hasError = fieldError !== undefined;
                  const fieldValueEmpty = values[fieldName].length === 0;
                  return (
                    <InputsLayoutWrapper>
                      <TextField
                        id={fieldName}
                        label={motivationSelectioninputLabel}
                        data-testid={fieldName}
                        hasError={hasError}
                        errorMessage={errors[fieldName]}
                        value={values[fieldName]}
                        name={fieldName}
                        onChange={(e): void => {
                          handleChange(e);
                        }}
                        isValidVisible={!fieldValueEmpty && !hasError}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                      />
                    </InputsLayoutWrapper>
                  );
                }}
              </Field>
            </Box>
          )}
        </fieldset>
      </FormFields>
    </FormGroup>
  );
};

export default DonationMotivation;
