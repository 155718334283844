import { Box } from "@cruk/cruk-react-components";
import styled from "styled-components";

const ErrorBox = styled(Box)`
  border: 4px solid ${({ theme }) => theme.tokenColors.red};
  padding: 0 ${({ theme }) => theme.spacing.s};
  font-weight: bold;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default ErrorBox;
